import classNames from '../_util/classNames';
import CloseCircleFilled from '@ant-design/icons-vue/CloseCircleFilled';
import PropTypes from '../_util/vue-types';
import { cloneElement } from '../_util/vnode';
import { defineComponent } from 'vue';
import { anyType, tuple } from '../_util/type';
import { hasAddon } from './util';
import { FormItemInputContext } from '../form/FormItemContext';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
const ClearableInputType = ['text', 'input'];
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'ClearableLabeledInput',
    inheritAttrs: false,
    props: {
        prefixCls: String,
        inputType: PropTypes.oneOf(tuple('text', 'input')),
        value: anyType(),
        defaultValue: anyType(),
        allowClear: { type: Boolean, default: undefined },
        element: anyType(),
        handleReset: Function,
        disabled: { type: Boolean, default: undefined },
        direction: { type: String },
        size: { type: String },
        suffix: anyType(),
        prefix: anyType(),
        addonBefore: anyType(),
        addonAfter: anyType(),
        readonly: { type: Boolean, default: undefined },
        focused: { type: Boolean, default: undefined },
        bordered: { type: Boolean, default: true },
        triggerFocus: { type: Function },
        hidden: Boolean,
        status: String,
        hashId: String,
    },
    setup(props, { slots, attrs }) {
        const statusContext = FormItemInputContext.useInject();
        const renderClearIcon = (prefixCls) => {
            const { value, disabled, readonly, handleReset, suffix = slots.suffix } = props;
            const needClear = !disabled && !readonly && value;
            const className = `${prefixCls}-clear-icon`;
            return (<CloseCircleFilled onClick={handleReset} 
            // Do not trigger onBlur when clear input
            onMousedown={e => e.preventDefault()} class={classNames({
                    [`${className}-hidden`]: !needClear,
                    [`${className}-has-suffix`]: !!suffix,
                }, className)} role="button"/>);
        };
        const renderTextAreaWithClearIcon = (prefixCls, element) => {
            const { value, allowClear, direction, bordered, hidden, status: customStatus, addonAfter = slots.addonAfter, addonBefore = slots.addonBefore, hashId, } = props;
            const { status: contextStatus, hasFeedback } = statusContext;
            if (!allowClear) {
                return cloneElement(element, {
                    value,
                    disabled: props.disabled,
                });
            }
            const affixWrapperCls = classNames(`${prefixCls}-affix-wrapper`, `${prefixCls}-affix-wrapper-textarea-with-clear-btn`, getStatusClassNames(`${prefixCls}-affix-wrapper`, getMergedStatus(contextStatus, customStatus), hasFeedback), {
                [`${prefixCls}-affix-wrapper-rtl`]: direction === 'rtl',
                [`${prefixCls}-affix-wrapper-borderless`]: !bordered,
                // className will go to addon wrapper
                [`${attrs.class}`]: !hasAddon({ addonAfter, addonBefore }) && attrs.class,
            }, hashId);
            return (<span class={affixWrapperCls} style={attrs.style} hidden={hidden}>
          {cloneElement(element, {
                    style: null,
                    value,
                    disabled: props.disabled,
                })}
          {renderClearIcon(prefixCls)}
        </span>);
        };
        return () => {
            var _a;
            const { prefixCls, inputType, element = (_a = slots.element) === null || _a === void 0 ? void 0 : _a.call(slots) } = props;
            if (inputType === ClearableInputType[0]) {
                return renderTextAreaWithClearIcon(prefixCls, element);
            }
            return null;
        };
    },
});
