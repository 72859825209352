export default function DropIndicator({ dropPosition, dropLevelOffset, indent, }) {
    const style = {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,
        backgroundColor: 'red',
        height: `${2}px`,
    };
    switch (dropPosition) {
        case -1:
            style.top = 0;
            style.left = `${-dropLevelOffset * indent}px`;
            break;
        case 1:
            style.bottom = 0;
            style.left = `${-dropLevelOffset * indent}px`;
            break;
        case 0:
            style.bottom = 0;
            style.left = `${indent}`;
            break;
    }
    return <div style={style}/>;
}
