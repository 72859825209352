export default function genSizeMapToken(token) {
    const { sizeUnit, sizeStep } = token;
    return {
        sizeXXL: sizeUnit * (sizeStep + 8),
        sizeXL: sizeUnit * (sizeStep + 4),
        sizeLG: sizeUnit * (sizeStep + 2),
        sizeMD: sizeUnit * (sizeStep + 1),
        sizeMS: sizeUnit * sizeStep,
        size: sizeUnit * sizeStep,
        sizeSM: sizeUnit * (sizeStep - 1),
        sizeXS: sizeUnit * (sizeStep - 2),
        sizeXXS: sizeUnit * (sizeStep - 3), // 4
    };
}
