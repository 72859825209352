const genLayoutLightStyle = token => {
    const { componentCls, colorBgContainer, colorBgBody, colorText } = token;
    return {
        [`${componentCls}-sider-light`]: {
            background: colorBgContainer,
            [`${componentCls}-sider-trigger`]: {
                color: colorText,
                background: colorBgContainer,
            },
            [`${componentCls}-sider-zero-width-trigger`]: {
                color: colorText,
                background: colorBgContainer,
                border: `1px solid ${colorBgBody}`,
                borderInlineStart: 0,
            },
        },
    };
};
export default genLayoutLightStyle;
