import Notice from '../vc-notification/Notice';
import useStyle from './style';
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import ExclamationCircleFilled from '@ant-design/icons-vue/ExclamationCircleFilled';
import CloseCircleFilled from '@ant-design/icons-vue/CloseCircleFilled';
import CheckCircleFilled from '@ant-design/icons-vue/CheckCircleFilled';
import InfoCircleFilled from '@ant-design/icons-vue/InfoCircleFilled';
import classNames from '../_util/classNames';
import { useConfigContextInject } from '../config-provider/context';
import { computed, defineComponent } from 'vue';
export const TypeIcon = {
    info: <InfoCircleFilled />,
    success: <CheckCircleFilled />,
    error: <CloseCircleFilled />,
    warning: <ExclamationCircleFilled />,
    loading: <LoadingOutlined />,
};
export const PureContent = defineComponent({
    name: 'PureContent',
    inheritAttrs: false,
    props: ['prefixCls', 'type', 'icon'],
    setup(props, { slots }) {
        return () => {
            var _a;
            return (<div class={classNames(`${props.prefixCls}-custom-content`, `${props.prefixCls}-${props.type}`)}>
        {props.icon || TypeIcon[props.type]}
        <span>{(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}</span>
      </div>);
        };
    },
});
/** @private Internal Component. Do not use in your production. */
export default defineComponent({
    name: 'PurePanel',
    inheritAttrs: false,
    props: ['prefixCls', 'class', 'type', 'icon', 'content'],
    setup(props, { slots, attrs }) {
        var _a;
        const { getPrefixCls } = useConfigContextInject();
        const prefixCls = computed(() => props.prefixCls || getPrefixCls('message'));
        const [, hashId] = useStyle(prefixCls);
        return (<Notice {...attrs} prefixCls={prefixCls.value} class={classNames(hashId.value, `${prefixCls.value}-notice-pure-panel`)} noticeKey="pure" duration={null}>
        <PureContent prefixCls={prefixCls.value} type={props.type} icon={props.icon}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </PureContent>
      </Notice>);
    },
});
