import Header from '../Header';
import { useInjectPanel } from '../../PanelContext';
import { formatValue } from '../../utils/dateUtil';
import useMergeProps from '../../hooks/useMergeProps';
function DateHeader(_props) {
    const props = useMergeProps(_props);
    const { prefixCls, generateConfig, locale, viewDate, onNextMonth, onPrevMonth, onNextYear, onPrevYear, onYearClick, onMonthClick, } = props;
    const { hideHeader } = useInjectPanel();
    if (hideHeader.value) {
        return null;
    }
    const headerPrefixCls = `${prefixCls}-header`;
    const monthsLocale = locale.shortMonths ||
        (generateConfig.locale.getShortMonths
            ? generateConfig.locale.getShortMonths(locale.locale)
            : []);
    const month = generateConfig.getMonth(viewDate);
    // =================== Month & Year ===================
    const yearNode = (<button type="button" key="year" onClick={onYearClick} tabindex={-1} class={`${prefixCls}-year-btn`}>
      {formatValue(viewDate, {
            locale,
            format: locale.yearFormat,
            generateConfig,
        })}
    </button>);
    const monthNode = (<button type="button" key="month" onClick={onMonthClick} tabindex={-1} class={`${prefixCls}-month-btn`}>
      {locale.monthFormat
            ? formatValue(viewDate, {
                locale,
                format: locale.monthFormat,
                generateConfig,
            })
            : monthsLocale[month]}
    </button>);
    const monthYearNodes = locale.monthBeforeYear ? [monthNode, yearNode] : [yearNode, monthNode];
    return (<Header {...props} prefixCls={headerPrefixCls} onSuperPrev={onPrevYear} onPrev={onPrevMonth} onNext={onNextMonth} onSuperNext={onNextYear}>
      {monthYearNodes}
    </Header>);
}
DateHeader.displayName = 'DateHeader';
DateHeader.inheritAttrs = false;
export default DateHeader;
