import Header from '../Header';
import { useInjectPanel } from '../../PanelContext';
import { formatValue } from '../../utils/dateUtil';
import useMergeProps from '../../hooks/useMergeProps';
function QuarterHeader(_props) {
    const props = useMergeProps(_props);
    const { prefixCls, generateConfig, locale, viewDate, onNextYear, onPrevYear, onYearClick } = props;
    const { hideHeader } = useInjectPanel();
    if (hideHeader.value) {
        return null;
    }
    const headerPrefixCls = `${prefixCls}-header`;
    return (<Header {...props} prefixCls={headerPrefixCls} onSuperPrev={onPrevYear} onSuperNext={onNextYear}>
      <button type="button" onClick={onYearClick} class={`${prefixCls}-year-btn`}>
        {formatValue(viewDate, {
            locale,
            format: locale.yearFormat,
            generateConfig,
        })}
      </button>
    </Header>);
}
QuarterHeader.displayName = 'QuarterHeader';
QuarterHeader.inheritAttrs = false;
export default QuarterHeader;
