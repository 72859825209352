const SPLIT = '%';
class Entity {
    constructor(instanceId) {
        /** @private Internal cache map. Do not access this directly */
        this.cache = new Map();
        this.instanceId = instanceId;
    }
    get(keys) {
        return this.cache.get(Array.isArray(keys) ? keys.join(SPLIT) : keys) || null;
    }
    update(keys, valueFn) {
        const path = Array.isArray(keys) ? keys.join(SPLIT) : keys;
        const prevValue = this.cache.get(path);
        const nextValue = valueFn(prevValue);
        if (nextValue === null) {
            this.cache.delete(path);
        }
        else {
            this.cache.set(path, nextValue);
        }
    }
}
export default Entity;
