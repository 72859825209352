export function isWindow(obj) {
    return obj !== null && obj !== undefined && obj === obj.window;
}
export default function getScroll(target, top) {
    var _a, _b;
    if (typeof window === 'undefined') {
        return 0;
    }
    const method = top ? 'scrollTop' : 'scrollLeft';
    let result = 0;
    if (isWindow(target)) {
        result = target[top ? 'scrollY' : 'scrollX'];
    }
    else if (target instanceof Document) {
        result = target.documentElement[method];
    }
    else if (target instanceof HTMLElement) {
        result = target[method];
    }
    else if (target) {
        // According to the type inference, the `target` is `never` type.
        // Since we configured the loose mode type checking, and supports mocking the target with such shape below::
        //    `{ documentElement: { scrollLeft: 200, scrollTop: 400 } }`,
        //    the program may falls into this branch.
        // Check the corresponding tests for details. Don't sure what is the real scenario this happens.
        result = target[method];
    }
    if (target && !isWindow(target) && typeof result !== 'number') {
        result = (_b = ((_a = target.ownerDocument) !== null && _a !== void 0 ? _a : target).documentElement) === null || _b === void 0 ? void 0 : _b[method];
    }
    return result;
}
