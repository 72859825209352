import { objectType, anyType } from '../../_util/type';
import { defineComponent } from 'vue';
export const baseOptionsProps = {
    value: String,
    disabled: Boolean,
    payload: objectType(),
};
export const optionProps = Object.assign(Object.assign({}, baseOptionsProps), { label: anyType([]) });
export const optionOptions = {
    name: 'Option',
    props: optionProps,
    render(_props, { slots }) {
        var _a;
        return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
    },
};
export default defineComponent(Object.assign({ compatConfig: { MODE: 3 } }, optionOptions));
