import { getCurrentScope, onScopeDispose } from 'vue';
/**
 * Call onScopeDispose() if it's inside a effect scope lifecycle, if not, do nothing
 *
 * @param fn
 */
export function tryOnScopeDispose(fn) {
    if (getCurrentScope()) {
        onScopeDispose(fn);
        return true;
    }
    return false;
}
