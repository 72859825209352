import { defineComponent, onMounted, ref } from 'vue';
import VCResizeObserver from '../../vc-resize-observer';
export default defineComponent({
    name: 'MeasureCell',
    props: ['columnKey'],
    setup(props, { emit }) {
        const tdRef = ref();
        onMounted(() => {
            if (tdRef.value) {
                emit('columnResize', props.columnKey, tdRef.value.offsetWidth);
            }
        });
        return () => {
            return (<VCResizeObserver onResize={({ offsetWidth }) => {
                    emit('columnResize', props.columnKey, offsetWidth);
                }}>
          <td ref={tdRef} style={{ padding: 0, border: 0, height: 0 }}>
            <div style={{ height: 0, overflow: 'hidden' }}>&nbsp;</div>
          </td>
        </VCResizeObserver>);
        };
    },
});
