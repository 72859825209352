import { computed } from 'vue';
import { useToken } from '../theme/internal';
export const responsiveArray = ['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
const getResponsiveMap = (token) => ({
    xs: `(max-width: ${token.screenXSMax}px)`,
    sm: `(min-width: ${token.screenSM}px)`,
    md: `(min-width: ${token.screenMD}px)`,
    lg: `(min-width: ${token.screenLG}px)`,
    xl: `(min-width: ${token.screenXL}px)`,
    xxl: `(min-width: ${token.screenXXL}px)`,
    xxxl: `{min-width: ${token.screenXXXL}px}`,
});
export default function useResponsiveObserver() {
    const [, token] = useToken();
    return computed(() => {
        const responsiveMap = getResponsiveMap(token.value);
        const subscribers = new Map();
        let subUid = -1;
        let screens = {};
        return {
            matchHandlers: {},
            dispatch(pointMap) {
                screens = pointMap;
                subscribers.forEach(func => func(screens));
                return subscribers.size >= 1;
            },
            subscribe(func) {
                if (!subscribers.size)
                    this.register();
                subUid += 1;
                subscribers.set(subUid, func);
                func(screens);
                return subUid;
            },
            unsubscribe(paramToken) {
                subscribers.delete(paramToken);
                if (!subscribers.size)
                    this.unregister();
            },
            unregister() {
                Object.keys(responsiveMap).forEach((screen) => {
                    const matchMediaQuery = responsiveMap[screen];
                    const handler = this.matchHandlers[matchMediaQuery];
                    handler === null || handler === void 0 ? void 0 : handler.mql.removeListener(handler === null || handler === void 0 ? void 0 : handler.listener);
                });
                subscribers.clear();
            },
            register() {
                Object.keys(responsiveMap).forEach((screen) => {
                    const matchMediaQuery = responsiveMap[screen];
                    const listener = ({ matches }) => {
                        this.dispatch(Object.assign(Object.assign({}, screens), { [screen]: matches }));
                    };
                    const mql = window.matchMedia(matchMediaQuery);
                    mql.addListener(listener);
                    this.matchHandlers[matchMediaQuery] = {
                        mql,
                        listener,
                    };
                    listener(mql);
                });
            },
            responsiveMap,
        };
    });
}
