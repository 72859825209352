import classNames from '../_util/classNames';
import createContext from '../_util/createContext';
import useConfigInject from '../config-provider/hooks/useConfigInject';
import useStyle from './style';
import { computed, defineComponent } from 'vue';
import PropTypes from '../_util/vue-types';
import { booleanType, tuple } from '../_util/type';
import { isEmpty } from 'lodash-es';
import { flattenChildren } from '../_util/props-util';
export const spaceCompactItemProps = () => ({
    compactSize: String,
    compactDirection: PropTypes.oneOf(tuple('horizontal', 'vertical')).def('horizontal'),
    isFirstItem: booleanType(),
    isLastItem: booleanType(),
});
export const SpaceCompactItemContext = createContext(null);
export const useCompactItemContext = (prefixCls, direction) => {
    const compactItemContext = SpaceCompactItemContext.useInject();
    const compactItemClassnames = computed(() => {
        if (!compactItemContext || isEmpty(compactItemContext))
            return '';
        const { compactDirection, isFirstItem, isLastItem } = compactItemContext;
        const separator = compactDirection === 'vertical' ? '-vertical-' : '-';
        return classNames({
            [`${prefixCls.value}-compact${separator}item`]: true,
            [`${prefixCls.value}-compact${separator}first-item`]: isFirstItem,
            [`${prefixCls.value}-compact${separator}last-item`]: isLastItem,
            [`${prefixCls.value}-compact${separator}item-rtl`]: direction.value === 'rtl',
        });
    });
    return {
        compactSize: computed(() => compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.compactSize),
        compactDirection: computed(() => compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.compactDirection),
        compactItemClassnames,
    };
};
export const NoCompactStyle = defineComponent({
    name: 'NoCompactStyle',
    setup(_, { slots }) {
        SpaceCompactItemContext.useProvide(null);
        return () => {
            var _a;
            return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
        };
    },
});
export const spaceCompactProps = () => ({
    prefixCls: String,
    size: {
        type: String,
    },
    direction: PropTypes.oneOf(tuple('horizontal', 'vertical')).def('horizontal'),
    align: PropTypes.oneOf(tuple('start', 'end', 'center', 'baseline')),
    block: { type: Boolean, default: undefined },
});
const CompactItem = defineComponent({
    name: 'CompactItem',
    props: spaceCompactItemProps(),
    setup(props, { slots }) {
        SpaceCompactItemContext.useProvide(props);
        return () => { var _a; return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots); };
    },
});
const Compact = defineComponent({
    name: 'ASpaceCompact',
    inheritAttrs: false,
    props: spaceCompactProps(),
    setup(props, { attrs, slots }) {
        const { prefixCls, direction: directionConfig } = useConfigInject('space-compact', props);
        const compactItemContext = SpaceCompactItemContext.useInject();
        const [wrapSSR, hashId] = useStyle(prefixCls);
        const clx = computed(() => {
            return classNames(prefixCls.value, hashId.value, {
                [`${prefixCls.value}-rtl`]: directionConfig.value === 'rtl',
                [`${prefixCls.value}-block`]: props.block,
                [`${prefixCls.value}-vertical`]: props.direction === 'vertical',
            });
        });
        return () => {
            var _a;
            const childNodes = flattenChildren(((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)) || []);
            // =========================== Render ===========================
            if (childNodes.length === 0) {
                return null;
            }
            return wrapSSR(<div {...attrs} class={[clx.value, attrs.class]}>
          {childNodes.map((child, i) => {
                    var _a;
                    const key = (child && child.key) || `${prefixCls.value}-item-${i}`;
                    const noCompactItemContext = !compactItemContext || isEmpty(compactItemContext);
                    return (<CompactItem key={key} compactSize={(_a = props.size) !== null && _a !== void 0 ? _a : 'middle'} compactDirection={props.direction} isFirstItem={i === 0 && (noCompactItemContext || (compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.isFirstItem))} isLastItem={i === childNodes.length - 1 &&
                            (noCompactItemContext || (compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.isLastItem))}>
                {child}
              </CompactItem>);
                })}
        </div>);
        };
    },
});
export default Compact;
