import raf from '../../_util/raf';
import { onBeforeUnmount, ref, shallowRef } from 'vue';
export function useLayoutState(defaultState) {
    const stateRef = shallowRef(defaultState);
    let rafId;
    const updateBatchRef = shallowRef([]);
    function setFrameState(updater) {
        updateBatchRef.value.push(updater);
        raf.cancel(rafId);
        rafId = raf(() => {
            const prevBatch = updateBatchRef.value;
            // const prevState = stateRef.value;
            updateBatchRef.value = [];
            prevBatch.forEach(batchUpdater => {
                stateRef.value = batchUpdater(stateRef.value);
            });
        });
    }
    onBeforeUnmount(() => {
        raf.cancel(rafId);
    });
    return [stateRef, setFrameState];
}
/** Lock frame, when frame pass reset the lock. */
export function useTimeoutLock(defaultState) {
    const frameRef = ref(defaultState || null);
    const timeoutRef = ref();
    function cleanUp() {
        clearTimeout(timeoutRef.value);
    }
    function setState(newState) {
        frameRef.value = newState;
        cleanUp();
        timeoutRef.value = setTimeout(() => {
            frameRef.value = null;
            timeoutRef.value = undefined;
        }, 100);
    }
    function getState() {
        return frameRef.value;
    }
    onBeforeUnmount(() => {
        cleanUp();
    });
    return [setState, getState];
}
