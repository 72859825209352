var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent } from 'vue';
import classNames from '../_util/classNames';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import DefaultEmptyImg from './empty';
import SimpleEmptyImg from './simple';
import { filterEmpty } from '../_util/props-util';
import { anyType, objectType, withInstall } from '../_util/type';
import useConfigInject from '../config-provider/hooks/useConfigInject';
import useStyle from './style';
const defaultEmptyImg = <DefaultEmptyImg />;
const simpleEmptyImg = <SimpleEmptyImg />;
export const emptyProps = () => ({
    prefixCls: String,
    imageStyle: objectType(),
    image: anyType(),
    description: anyType(),
});
const Empty = defineComponent({
    name: 'AEmpty',
    compatConfig: { MODE: 3 },
    inheritAttrs: false,
    props: emptyProps(),
    setup(props, { slots = {}, attrs }) {
        const { direction, prefixCls: prefixClsRef } = useConfigInject('empty', props);
        const [wrapSSR, hashId] = useStyle(prefixClsRef);
        return () => {
            var _a, _b;
            const prefixCls = prefixClsRef.value;
            const _c = Object.assign(Object.assign({}, props), attrs), { image = ((_a = slots.image) === null || _a === void 0 ? void 0 : _a.call(slots)) || defaultEmptyImg, description = ((_b = slots.description) === null || _b === void 0 ? void 0 : _b.call(slots)) || undefined, imageStyle, class: className = '' } = _c, restProps = __rest(_c, ["image", "description", "imageStyle", "class"]);
            return wrapSSR(<LocaleReceiver componentName="Empty" children={(locale) => {
                    const des = typeof description !== 'undefined' ? description : locale.description;
                    const alt = typeof des === 'string' ? des : 'empty';
                    let imageNode = null;
                    if (typeof image === 'string') {
                        imageNode = <img alt={alt} src={image}/>;
                    }
                    else {
                        imageNode = image;
                    }
                    return (<div class={classNames(prefixCls, className, hashId.value, {
                            [`${prefixCls}-normal`]: image === simpleEmptyImg,
                            [`${prefixCls}-rtl`]: direction.value === 'rtl',
                        })} {...restProps}>
                <div class={`${prefixCls}-image`} style={imageStyle}>
                  {imageNode}
                </div>
                {des && <p class={`${prefixCls}-description`}>{des}</p>}
                {slots.default && (<div class={`${prefixCls}-footer`}>{filterEmpty(slots.default())}</div>)}
              </div>);
                }}/>);
        };
    },
});
Empty.PRESENTED_IMAGE_DEFAULT = defaultEmptyImg;
Empty.PRESENTED_IMAGE_SIMPLE = simpleEmptyImg;
export default withInstall(Empty);
