import PropTypes, { withUndefined } from '../_util/vue-types';
import { defineComponent } from 'vue';
import classNames from '../_util/classNames';
import { booleanType, stringType, functionType } from '../_util/type';
import omit from '../_util/omit';
function isString(str) {
    return typeof str === 'string';
}
function noop() { }
export const VcStepProps = () => ({
    prefixCls: String,
    itemWidth: String,
    active: { type: Boolean, default: undefined },
    disabled: { type: Boolean, default: undefined },
    status: stringType(),
    iconPrefix: String,
    icon: PropTypes.any,
    adjustMarginRight: String,
    stepNumber: Number,
    stepIndex: Number,
    description: PropTypes.any,
    title: PropTypes.any,
    subTitle: PropTypes.any,
    progressDot: withUndefined(PropTypes.oneOfType([PropTypes.looseBool, PropTypes.func])),
    tailContent: PropTypes.any,
    icons: PropTypes.shape({
        finish: PropTypes.any,
        error: PropTypes.any,
    }).loose,
    onClick: functionType(),
    onStepClick: functionType(),
    stepIcon: functionType(),
    itemRender: functionType(),
    __legacy: booleanType(),
});
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'Step',
    inheritAttrs: false,
    props: VcStepProps(),
    setup(props, { slots, emit, attrs }) {
        const onItemClick = e => {
            emit('click', e);
            emit('stepClick', props.stepIndex);
        };
        // if (props.__legacy !== false) {
        //   warning(
        //     false,
        //     'Steps',
        //     'Step is deprecated, and not support inline type. Please use `items` directly. ',
        //   );
        // }
        const renderIconNode = ({ icon, title, description }) => {
            const { prefixCls, stepNumber, status, iconPrefix, icons, progressDot = slots.progressDot, stepIcon = slots.stepIcon, } = props;
            let iconNode;
            const iconClassName = classNames(`${prefixCls}-icon`, `${iconPrefix}icon`, {
                [`${iconPrefix}icon-${icon}`]: icon && isString(icon),
                [`${iconPrefix}icon-check`]: !icon && status === 'finish' && ((icons && !icons.finish) || !icons),
                [`${iconPrefix}icon-cross`]: !icon && status === 'error' && ((icons && !icons.error) || !icons),
            });
            const iconDot = <span class={`${prefixCls}-icon-dot`}/>;
            // `progressDot` enjoy the highest priority
            if (progressDot) {
                if (typeof progressDot === 'function') {
                    iconNode = (<span class={`${prefixCls}-icon`}>
              {progressDot({
                            iconDot,
                            index: stepNumber - 1,
                            status,
                            title,
                            description,
                            prefixCls,
                        })}
            </span>);
                }
                else {
                    iconNode = <span class={`${prefixCls}-icon`}>{iconDot}</span>;
                }
            }
            else if (icon && !isString(icon)) {
                iconNode = <span class={`${prefixCls}-icon`}>{icon}</span>;
            }
            else if (icons && icons.finish && status === 'finish') {
                iconNode = <span class={`${prefixCls}-icon`}>{icons.finish}</span>;
            }
            else if (icons && icons.error && status === 'error') {
                iconNode = <span class={`${prefixCls}-icon`}>{icons.error}</span>;
            }
            else if (icon || status === 'finish' || status === 'error') {
                iconNode = <span class={iconClassName}/>;
            }
            else {
                iconNode = <span class={`${prefixCls}-icon`}>{stepNumber}</span>;
            }
            if (stepIcon) {
                iconNode = stepIcon({
                    index: stepNumber - 1,
                    status,
                    title,
                    description,
                    node: iconNode,
                });
            }
            return iconNode;
        };
        return () => {
            var _a, _b, _c, _d;
            const { prefixCls, itemWidth, active, status = 'wait', tailContent, adjustMarginRight, disabled, title = (_a = slots.title) === null || _a === void 0 ? void 0 : _a.call(slots), description = (_b = slots.description) === null || _b === void 0 ? void 0 : _b.call(slots), subTitle = (_c = slots.subTitle) === null || _c === void 0 ? void 0 : _c.call(slots), icon = (_d = slots.icon) === null || _d === void 0 ? void 0 : _d.call(slots), onClick, onStepClick, } = props;
            const mergedStatus = status || 'wait';
            const classString = classNames(`${prefixCls}-item`, `${prefixCls}-item-${mergedStatus}`, {
                [`${prefixCls}-item-custom`]: icon,
                [`${prefixCls}-item-active`]: active,
                [`${prefixCls}-item-disabled`]: disabled === true,
            });
            const stepItemStyle = {};
            if (itemWidth) {
                stepItemStyle.width = itemWidth;
            }
            if (adjustMarginRight) {
                stepItemStyle.marginRight = adjustMarginRight;
            }
            const accessibilityProps = {
                onClick: onClick || noop,
            };
            if (onStepClick && !disabled) {
                accessibilityProps.role = 'button';
                accessibilityProps.tabindex = 0;
                accessibilityProps.onClick = onItemClick;
            }
            const stepNode = (<div {...omit(attrs, ['__legacy'])} class={[classString, attrs.class]} style={[attrs.style, stepItemStyle]}>
          <div {...accessibilityProps} class={`${prefixCls}-item-container`}>
            <div class={`${prefixCls}-item-tail`}>{tailContent}</div>
            <div class={`${prefixCls}-item-icon`}>
              {renderIconNode({ icon, title, description })}
            </div>
            <div class={`${prefixCls}-item-content`}>
              <div class={`${prefixCls}-item-title`}>
                {title}
                {subTitle && (<div title={typeof subTitle === 'string' ? subTitle : undefined} class={`${prefixCls}-item-subtitle`}>
                    {subTitle}
                  </div>)}
              </div>
              {description && <div class={`${prefixCls}-item-description`}>{description}</div>}
            </div>
          </div>
        </div>);
            if (props.itemRender) {
                return props.itemRender(stepNode);
            }
            return stepNode;
        };
    },
});
