import { Transition } from 'vue';
import { getMotion } from '../utils/motionUtil';
export default function Mask(props) {
    const { prefixCls, visible, zIndex, mask, maskAnimation, maskTransitionName } = props;
    if (!mask) {
        return null;
    }
    let motion = {};
    if (maskTransitionName || maskAnimation) {
        motion = getMotion({
            prefixCls,
            transitionName: maskTransitionName,
            animation: maskAnimation,
        });
    }
    return (<Transition appear {...motion}>
      <div v-if={visible} style={{ zIndex }} class={`${prefixCls}-mask`}/>
    </Transition>);
}
Mask.displayName = 'Mask';
