import { defineComponent } from 'vue';
export default defineComponent({
    name: 'PresetPanel',
    props: {
        prefixCls: String,
        presets: {
            type: Array,
            default: () => [],
        },
        onClick: Function,
        onHover: Function,
    },
    setup(props) {
        return () => {
            if (!props.presets.length) {
                return null;
            }
            return (<div class={`${props.prefixCls}-presets`}>
          <ul>
            {props.presets.map(({ label, value }, index) => (<li key={index} onClick={() => {
                        props.onClick(value);
                    }} onMouseenter={() => {
                        var _a;
                        (_a = props.onHover) === null || _a === void 0 ? void 0 : _a.call(props, value);
                    }} onMouseleave={() => {
                        var _a;
                        (_a = props.onHover) === null || _a === void 0 ? void 0 : _a.call(props, null);
                    }}>
                {label}
              </li>))}
          </ul>
        </div>);
        };
    },
});
