const genSpaceCompactStyle = token => {
    const { componentCls } = token;
    return {
        [componentCls]: {
            display: 'inline-flex',
            '&-block': {
                display: 'flex',
                width: '100%',
            },
            '&-vertical': {
                flexDirection: 'column',
            },
        },
    };
};
// ============================== Export ==============================
export default genSpaceCompactStyle;
