import { computed, h, inject } from 'vue';
import { defaultConfigProvider, configProviderKey } from '../context';
import { useInjectDisabled } from '../DisabledContext';
import { DefaultRenderEmpty } from '../renderEmpty';
import { useInjectSize } from '../SizeContext';
export default (name, props) => {
    const sizeContext = useInjectSize();
    const disabledContext = useInjectDisabled();
    const configProvider = inject(configProviderKey, Object.assign(Object.assign({}, defaultConfigProvider), { renderEmpty: (name) => h(DefaultRenderEmpty, { componentName: name }) }));
    const prefixCls = computed(() => configProvider.getPrefixCls(name, props.prefixCls));
    const direction = computed(() => { var _a, _b; return (_a = props.direction) !== null && _a !== void 0 ? _a : (_b = configProvider.direction) === null || _b === void 0 ? void 0 : _b.value; });
    const iconPrefixCls = computed(() => { var _a; return (_a = props.iconPrefixCls) !== null && _a !== void 0 ? _a : configProvider.iconPrefixCls.value; });
    const rootPrefixCls = computed(() => configProvider.getPrefixCls());
    const autoInsertSpaceInButton = computed(() => { var _a; return (_a = configProvider.autoInsertSpaceInButton) === null || _a === void 0 ? void 0 : _a.value; });
    const renderEmpty = configProvider.renderEmpty;
    const space = configProvider.space;
    const pageHeader = configProvider.pageHeader;
    const form = configProvider.form;
    const getTargetContainer = computed(() => { var _a, _b; return (_a = props.getTargetContainer) !== null && _a !== void 0 ? _a : (_b = configProvider.getTargetContainer) === null || _b === void 0 ? void 0 : _b.value; });
    const getPopupContainer = computed(() => { var _a, _b, _c; return (_b = (_a = props.getContainer) !== null && _a !== void 0 ? _a : props.getPopupContainer) !== null && _b !== void 0 ? _b : (_c = configProvider.getPopupContainer) === null || _c === void 0 ? void 0 : _c.value; });
    const dropdownMatchSelectWidth = computed(() => { var _a, _b; return (_a = props.dropdownMatchSelectWidth) !== null && _a !== void 0 ? _a : (_b = configProvider.dropdownMatchSelectWidth) === null || _b === void 0 ? void 0 : _b.value; });
    const virtual = computed(() => {
        var _a;
        return (props.virtual === undefined
            ? ((_a = configProvider.virtual) === null || _a === void 0 ? void 0 : _a.value) !== false
            : props.virtual !== false) && dropdownMatchSelectWidth.value !== false;
    });
    const size = computed(() => props.size || sizeContext.value);
    const autocomplete = computed(() => { var _a, _b, _c; return (_a = props.autocomplete) !== null && _a !== void 0 ? _a : (_c = (_b = configProvider.input) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.autocomplete; });
    const disabled = computed(() => { var _a; return (_a = props.disabled) !== null && _a !== void 0 ? _a : disabledContext.value; });
    const csp = computed(() => { var _a; return (_a = props.csp) !== null && _a !== void 0 ? _a : configProvider.csp; });
    const wave = computed(() => { var _a, _b; return (_a = props.wave) !== null && _a !== void 0 ? _a : (_b = configProvider.wave) === null || _b === void 0 ? void 0 : _b.value; });
    return {
        configProvider,
        prefixCls,
        direction,
        size,
        getTargetContainer,
        getPopupContainer,
        space,
        pageHeader,
        form,
        autoInsertSpaceInButton,
        renderEmpty,
        virtual,
        dropdownMatchSelectWidth,
        rootPrefixCls,
        getPrefixCls: configProvider.getPrefixCls,
        autocomplete,
        csp,
        iconPrefixCls,
        disabled,
        select: configProvider.select,
        wave,
    };
};
