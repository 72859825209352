import KeyCode from '../../../_util/KeyCode';
const onKeyDown = event => {
    const { keyCode } = event;
    if (keyCode === KeyCode.ENTER) {
        event.stopPropagation();
    }
};
const FilterDropdownMenuWrapper = (_props, { slots }) => {
    var _a;
    return (<div onClick={e => e.stopPropagation()} onKeydown={onKeyDown}>
    {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
  </div>);
};
export default FilterDropdownMenuWrapper;
