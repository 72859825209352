import { textEllipsis } from '../../style';
const genEllipsisStyle = token => {
    const { componentCls } = token;
    return {
        [`${componentCls}-wrapper`]: {
            [`${componentCls}-cell-ellipsis`]: Object.assign(Object.assign({}, textEllipsis), { wordBreak: 'keep-all', 
                // Fixed first or last should special process
                [`
          &${componentCls}-cell-fix-left-last,
          &${componentCls}-cell-fix-right-first
        `]: {
                    overflow: 'visible',
                    [`${componentCls}-cell-content`]: {
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                }, [`${componentCls}-column-title`]: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordBreak: 'keep-all',
                } }),
        },
    };
};
export default genEllipsisStyle;
