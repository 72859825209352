function compactItemVerticalBorder(token, parentCls) {
    return {
        // border collapse
        [`&-item:not(${parentCls}-last-item)`]: {
            marginBottom: -token.lineWidth,
        },
        '&-item': {
            '&:hover,&:focus,&:active': {
                zIndex: 2,
            },
            '&[disabled]': {
                zIndex: 0,
            },
        },
    };
}
function compactItemBorderVerticalRadius(prefixCls, parentCls) {
    return {
        [`&-item:not(${parentCls}-first-item):not(${parentCls}-last-item)`]: {
            borderRadius: 0,
        },
        [`&-item${parentCls}-first-item:not(${parentCls}-last-item)`]: {
            [`&, &${prefixCls}-sm, &${prefixCls}-lg`]: {
                borderEndEndRadius: 0,
                borderEndStartRadius: 0,
            },
        },
        [`&-item${parentCls}-last-item:not(${parentCls}-first-item)`]: {
            [`&, &${prefixCls}-sm, &${prefixCls}-lg`]: {
                borderStartStartRadius: 0,
                borderStartEndRadius: 0,
            },
        },
    };
}
export function genCompactItemVerticalStyle(token) {
    const compactCls = `${token.componentCls}-compact-vertical`;
    return {
        [compactCls]: Object.assign(Object.assign({}, compactItemVerticalBorder(token, compactCls)), compactItemBorderVerticalRadius(token.componentCls, compactCls)),
    };
}
