import { defineComponent, computed, ref } from 'vue';
import KeyCode from '../../../_util/KeyCode';
import classNames from '../../../_util/classNames';
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'TabNode',
    props: {
        id: { type: String },
        prefixCls: { type: String },
        tab: { type: Object },
        active: { type: Boolean },
        closable: { type: Boolean },
        editable: { type: Object },
        onClick: { type: Function },
        onResize: {
            type: Function,
        },
        renderWrapper: { type: Function },
        removeAriaLabel: { type: String },
        // onRemove: { type: Function as PropType<() => void> },
        onFocus: { type: Function },
    },
    emits: ['click', 'resize', 'remove', 'focus'],
    setup(props, { expose, attrs }) {
        const domRef = ref();
        function onInternalClick(e) {
            var _a;
            if ((_a = props.tab) === null || _a === void 0 ? void 0 : _a.disabled) {
                return;
            }
            props.onClick(e);
        }
        expose({
            domRef,
        });
        // onBeforeUnmount(() => {
        //   props.onRemove();
        // });
        function onRemoveTab(event) {
            var _a;
            event.preventDefault();
            event.stopPropagation();
            props.editable.onEdit('remove', {
                key: (_a = props.tab) === null || _a === void 0 ? void 0 : _a.key,
                event,
            });
        }
        const removable = computed(() => { var _a; return props.editable && props.closable !== false && !((_a = props.tab) === null || _a === void 0 ? void 0 : _a.disabled); });
        return () => {
            var _a;
            const { prefixCls, id, active, tab: { key, tab, disabled, closeIcon }, renderWrapper, removeAriaLabel, editable, onFocus, } = props;
            const tabPrefix = `${prefixCls}-tab`;
            const node = (<div key={key} ref={domRef} class={classNames(tabPrefix, {
                    [`${tabPrefix}-with-remove`]: removable.value,
                    [`${tabPrefix}-active`]: active,
                    [`${tabPrefix}-disabled`]: disabled,
                })} style={attrs.style} onClick={onInternalClick}>
          {/* Primary Tab Button */}
          <div role="tab" aria-selected={active} id={id && `${id}-tab-${key}`} class={`${tabPrefix}-btn`} aria-controls={id && `${id}-panel-${key}`} aria-disabled={disabled} tabindex={disabled ? null : 0} onClick={e => {
                    e.stopPropagation();
                    onInternalClick(e);
                }} onKeydown={e => {
                    if ([KeyCode.SPACE, KeyCode.ENTER].includes(e.which)) {
                        e.preventDefault();
                        onInternalClick(e);
                    }
                }} onFocus={onFocus}>
            {typeof tab === 'function' ? tab() : tab}
          </div>

          {/* Remove Button */}
          {removable.value && (<button type="button" aria-label={removeAriaLabel || 'remove'} tabindex={0} class={`${tabPrefix}-remove`} onClick={e => {
                        e.stopPropagation();
                        onRemoveTab(e);
                    }}>
              {(closeIcon === null || closeIcon === void 0 ? void 0 : closeIcon()) || ((_a = editable.removeIcon) === null || _a === void 0 ? void 0 : _a.call(editable)) || '×'}
            </button>)}
        </div>);
            return renderWrapper ? renderWrapper(node) : node;
        };
    },
});
