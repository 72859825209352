export const propTypes = {
    gapDegree: Number,
    gapPosition: {
        type: String,
    },
    percent: {
        type: [Array, Number],
    },
    prefixCls: String,
    strokeColor: {
        type: [Object, String, Array],
    },
    strokeLinecap: {
        type: String,
    },
    strokeWidth: Number,
    trailColor: String,
    trailWidth: Number,
    transition: String,
};
