import { toRaw, watchEffect, unref, watch, ref } from 'vue';
export default function useMergedState(defaultStateValue, option) {
    const { defaultValue, value = ref() } = option || {};
    let initValue = typeof defaultStateValue === 'function' ? defaultStateValue() : defaultStateValue;
    if (value.value !== undefined) {
        initValue = unref(value);
    }
    if (defaultValue !== undefined) {
        initValue = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    }
    const innerValue = ref(initValue);
    const mergedValue = ref(initValue);
    watchEffect(() => {
        let val = value.value !== undefined ? value.value : innerValue.value;
        if (option.postState) {
            val = option.postState(val);
        }
        mergedValue.value = val;
    });
    function triggerChange(newValue) {
        const preVal = mergedValue.value;
        innerValue.value = newValue;
        if (toRaw(mergedValue.value) !== newValue && option.onChange) {
            option.onChange(newValue, preVal);
        }
    }
    // Effect of reset value to `undefined`
    watch(value, () => {
        innerValue.value = value.value;
    });
    return [mergedValue, triggerChange];
}
