import Col from '../grid/Col';
import { useProvideForm, useInjectForm, useProvideFormItemPrefix } from './context';
import ErrorList from './ErrorList';
import classNames from '../_util/classNames';
import { computed, defineComponent } from 'vue';
import { filterEmpty } from '../_util/props-util';
const FormItemInput = defineComponent({
    compatConfig: { MODE: 3 },
    slots: Object,
    inheritAttrs: false,
    props: [
        'prefixCls',
        'errors',
        'hasFeedback',
        'onDomErrorVisibleChange',
        'wrapperCol',
        'help',
        'extra',
        'status',
        'marginBottom',
        'onErrorVisibleChanged',
    ],
    setup(props, { slots }) {
        const formContext = useInjectForm();
        const { wrapperCol: contextWrapperCol } = formContext;
        // Pass to sub FormItem should not with col info
        const subFormContext = Object.assign({}, formContext);
        delete subFormContext.labelCol;
        delete subFormContext.wrapperCol;
        useProvideForm(subFormContext);
        useProvideFormItemPrefix({
            prefixCls: computed(() => props.prefixCls),
            status: computed(() => props.status),
        });
        return () => {
            var _a, _b, _c;
            const { prefixCls, wrapperCol, marginBottom, onErrorVisibleChanged, help = (_a = slots.help) === null || _a === void 0 ? void 0 : _a.call(slots), errors = filterEmpty((_b = slots.errors) === null || _b === void 0 ? void 0 : _b.call(slots)), 
            // hasFeedback,
            // status,
            extra = (_c = slots.extra) === null || _c === void 0 ? void 0 : _c.call(slots), } = props;
            const baseClassName = `${prefixCls}-item`;
            const mergedWrapperCol = wrapperCol || (contextWrapperCol === null || contextWrapperCol === void 0 ? void 0 : contextWrapperCol.value) || {};
            const className = classNames(`${baseClassName}-control`, mergedWrapperCol.class);
            // Should provides additional icon if `hasFeedback`
            // const IconNode = status && iconMap[status];
            return (<Col {...mergedWrapperCol} class={className} v-slots={{
                    default: () => {
                        var _a;
                        return (<>
                <div class={`${baseClassName}-control-input`}>
                  <div class={`${baseClassName}-control-input-content`}>{(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}</div>
                </div>
                {marginBottom !== null || errors.length ? (<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <ErrorList errors={errors} help={help} class={`${baseClassName}-explain-connected`} onErrorVisibleChanged={onErrorVisibleChanged}/>
                    {!!marginBottom && <div style={{ width: 0, height: `${marginBottom}px` }}/>}
                  </div>) : null}
                {extra ? <div class={`${baseClassName}-extra`}>{extra}</div> : null}
              </>);
                    },
                }}></Col>);
        };
    },
});
export default FormItemInput;
