import { defaultConfig } from '../../theme/internal';
import { computed } from 'vue';
export default function useTheme(theme, parentTheme) {
    const themeConfig = computed(() => (theme === null || theme === void 0 ? void 0 : theme.value) || {});
    const parentThemeConfig = computed(() => themeConfig.value.inherit === false || !(parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.value) ? defaultConfig : parentTheme.value);
    const mergedTheme = computed(() => {
        if (!(theme === null || theme === void 0 ? void 0 : theme.value)) {
            return parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.value;
        }
        // Override
        const mergedComponents = Object.assign({}, parentThemeConfig.value.components);
        Object.keys(theme.value.components || {}).forEach(componentName => {
            mergedComponents[componentName] = Object.assign(Object.assign({}, mergedComponents[componentName]), theme.value.components[componentName]);
        });
        // Base token
        return Object.assign(Object.assign(Object.assign({}, parentThemeConfig.value), themeConfig.value), { token: Object.assign(Object.assign({}, parentThemeConfig.value.token), themeConfig.value.token), components: mergedComponents });
    });
    return mergedTheme;
}
