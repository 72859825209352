import warning from './warning';
/**
 * Deeply compares two object literals.
 * @param obj1 object 1
 * @param obj2 object 2
 * @param shallow shallow compare
 * @returns
 */
function isEqual(obj1, obj2, shallow = false) {
    // https://github.com/mapbox/mapbox-gl-js/pull/5979/files#diff-fde7145050c47cc3a306856efd5f9c3016e86e859de9afbd02c879be5067e58f
    const refSet = new Set();
    function deepEqual(a, b, level = 1) {
        const circular = refSet.has(a);
        warning(!circular, 'Warning: There may be circular references');
        if (circular) {
            return false;
        }
        if (a === b) {
            return true;
        }
        if (shallow && level > 1) {
            return false;
        }
        refSet.add(a);
        const newLevel = level + 1;
        if (Array.isArray(a)) {
            if (!Array.isArray(b) || a.length !== b.length) {
                return false;
            }
            for (let i = 0; i < a.length; i++) {
                if (!deepEqual(a[i], b[i], newLevel)) {
                    return false;
                }
            }
            return true;
        }
        if (a && b && typeof a === 'object' && typeof b === 'object') {
            const keys = Object.keys(a);
            if (keys.length !== Object.keys(b).length) {
                return false;
            }
            return keys.every(key => deepEqual(a[key], b[key], newLevel));
        }
        // other
        return false;
    }
    return deepEqual(obj1, obj2);
}
export default isEqual;
