import { createVNode, render as vueRender } from 'vue';
import ConfirmDialog from './ConfirmDialog';
import ConfigProvider, { globalConfigForApi } from '../config-provider';
import omit from '../_util/omit';
import { triggerVNodeUpdate } from '../_util/vnode';
import { getConfirmLocale } from './locale';
import destroyFns from './destroyFns';
const confirm = (config) => {
    const container = document.createDocumentFragment();
    let currentConfig = Object.assign(Object.assign({}, omit(config, ['parentContext', 'appContext'])), { close, open: true });
    let confirmDialogInstance = null;
    function destroy(...args) {
        if (confirmDialogInstance) {
            // destroy
            vueRender(null, container);
            confirmDialogInstance = null;
        }
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(() => { }, ...args.slice(1));
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }
    function close(...args) {
        currentConfig = Object.assign(Object.assign({}, currentConfig), { open: false, afterClose: () => {
                if (typeof config.afterClose === 'function') {
                    config.afterClose();
                }
                destroy.apply(this, args);
            } });
        // Legacy support
        if (currentConfig.visible) {
            delete currentConfig.visible;
        }
        update(currentConfig);
    }
    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        }
        else {
            currentConfig = Object.assign(Object.assign({}, currentConfig), configUpdate);
        }
        if (confirmDialogInstance) {
            triggerVNodeUpdate(confirmDialogInstance, currentConfig, container);
        }
    }
    const Wrapper = (p) => {
        const global = globalConfigForApi;
        const rootPrefixCls = global.prefixCls;
        const prefixCls = p.prefixCls || `${rootPrefixCls}-modal`;
        const iconPrefixCls = global.iconPrefixCls;
        const runtimeLocale = getConfirmLocale();
        return (<ConfigProvider {...global} prefixCls={rootPrefixCls}>
        <ConfirmDialog {...p} rootPrefixCls={rootPrefixCls} prefixCls={prefixCls} iconPrefixCls={iconPrefixCls} locale={runtimeLocale} cancelText={p.cancelText || runtimeLocale.cancelText}></ConfirmDialog>
      </ConfigProvider>);
    };
    function render(props) {
        const vm = createVNode(Wrapper, Object.assign({}, props));
        vm.appContext = config.parentContext || config.appContext || vm.appContext;
        vueRender(vm, container);
        return vm;
    }
    confirmDialogInstance = render(currentConfig);
    destroyFns.push(close);
    return {
        destroy: close,
        update,
    };
};
export default confirm;
export function withWarn(props) {
    return Object.assign(Object.assign({}, props), { type: 'warning' });
}
export function withInfo(props) {
    return Object.assign(Object.assign({}, props), { type: 'info' });
}
export function withSuccess(props) {
    return Object.assign(Object.assign({}, props), { type: 'success' });
}
export function withError(props) {
    return Object.assign(Object.assign({}, props), { type: 'error' });
}
export function withConfirm(props) {
    return Object.assign(Object.assign({}, props), { type: 'confirm' });
}
