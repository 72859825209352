/* eslint-disable no-redeclare */
import { useStyleRegister } from '../../_util/cssinjs';
import { genCommonStyle, genLinkStyle } from '../../style';
import { mergeToken, statisticToken, useToken } from '../internal';
import { computed } from 'vue';
import { useConfigContextInject } from '../../config-provider/context';
export default function genComponentStyleHook(component, styleFn, getDefaultToken) {
    return (_prefixCls) => {
        const prefixCls = computed(() => _prefixCls === null || _prefixCls === void 0 ? void 0 : _prefixCls.value);
        const [theme, token, hashId] = useToken();
        const { getPrefixCls, iconPrefixCls } = useConfigContextInject();
        const rootPrefixCls = computed(() => getPrefixCls());
        const sharedInfo = computed(() => {
            return {
                theme: theme.value,
                token: token.value,
                hashId: hashId.value,
                path: ['Shared', rootPrefixCls.value],
            };
        });
        // Generate style for all a tags in antd component.
        useStyleRegister(sharedInfo, () => [
            {
                // Link
                '&': genLinkStyle(token.value),
            },
        ]);
        const componentInfo = computed(() => {
            return {
                theme: theme.value,
                token: token.value,
                hashId: hashId.value,
                path: [component, prefixCls.value, iconPrefixCls.value],
            };
        });
        return [
            useStyleRegister(componentInfo, () => {
                const { token: proxyToken, flush } = statisticToken(token.value);
                const defaultComponentToken = typeof getDefaultToken === 'function'
                    ? getDefaultToken(proxyToken)
                    : getDefaultToken;
                const mergedComponentToken = Object.assign(Object.assign({}, defaultComponentToken), token.value[component]);
                const componentCls = `.${prefixCls.value}`;
                const mergedToken = mergeToken(proxyToken, {
                    componentCls,
                    prefixCls: prefixCls.value,
                    iconCls: `.${iconPrefixCls.value}`,
                    antCls: `.${rootPrefixCls.value}`,
                }, mergedComponentToken);
                const styleInterpolation = styleFn(mergedToken, {
                    hashId: hashId.value,
                    prefixCls: prefixCls.value,
                    rootPrefixCls: rootPrefixCls.value,
                    iconPrefixCls: iconPrefixCls.value,
                    overrideComponentToken: token.value[component],
                });
                flush(component, mergedComponentToken);
                return [genCommonStyle(token.value, prefixCls.value), styleInterpolation];
            }),
            hashId,
        ];
    };
}
