const initDefaultProps = (types, defaultProps) => {
    const propTypes = Object.assign({}, types);
    Object.keys(defaultProps).forEach(k => {
        const prop = propTypes[k];
        if (prop) {
            if (prop.type || prop.default) {
                prop.default = defaultProps[k];
            }
            else if (prop.def) {
                prop.def(defaultProps[k]);
            }
            else {
                propTypes[k] = { type: prop, default: defaultProps[k] };
            }
        }
        else {
            throw new Error(`not have ${k} prop`);
        }
    });
    return propTypes;
};
export default initDefaultProps;
