export { operationUnit } from './operationUnit';
export { roundedArrow } from './roundedArrow';
export { genPresetColor } from './presetColor';
export const textEllipsis = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};
export const resetComponent = (token) => ({
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    color: token.colorText,
    fontSize: token.fontSize,
    // font-variant: @font-variant-base;
    lineHeight: token.lineHeight,
    listStyle: 'none',
    // font-feature-settings: @font-feature-settings-base;
    fontFamily: token.fontFamily,
});
export const resetIcon = () => ({
    display: 'inline-flex',
    alignItems: 'center',
    color: 'inherit',
    fontStyle: 'normal',
    lineHeight: 0,
    textAlign: 'center',
    textTransform: 'none',
    // for SVG icon, see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
    verticalAlign: '-0.125em',
    textRendering: 'optimizeLegibility',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    '> *': {
        lineHeight: 1,
    },
    svg: {
        display: 'inline-block',
    },
});
export const clearFix = () => ({
    // https://github.com/ant-design/ant-design/issues/21301#issuecomment-583955229
    '&::before': {
        display: 'table',
        content: '""',
    },
    '&::after': {
        // https://github.com/ant-design/ant-design/issues/21864
        display: 'table',
        clear: 'both',
        content: '""',
    },
});
export const genLinkStyle = (token) => ({
    a: {
        color: token.colorLink,
        textDecoration: token.linkDecoration,
        backgroundColor: 'transparent',
        outline: 'none',
        cursor: 'pointer',
        transition: `color ${token.motionDurationSlow}`,
        '-webkit-text-decoration-skip': 'objects',
        '&:hover': {
            color: token.colorLinkHover,
        },
        '&:active': {
            color: token.colorLinkActive,
        },
        [`&:active,
  &:hover`]: {
            textDecoration: token.linkHoverDecoration,
            outline: 0,
        },
        // https://github.com/ant-design/ant-design/issues/22503
        '&:focus': {
            textDecoration: token.linkFocusDecoration,
            outline: 0,
        },
        '&[disabled]': {
            color: token.colorTextDisabled,
            cursor: 'not-allowed',
        },
    },
});
export const genCommonStyle = (token, componentPrefixCls) => {
    const { fontFamily, fontSize } = token;
    const rootPrefixSelector = `[class^="${componentPrefixCls}"], [class*=" ${componentPrefixCls}"]`;
    return {
        [rootPrefixSelector]: {
            fontFamily,
            fontSize,
            boxSizing: 'border-box',
            '&::before, &::after': {
                boxSizing: 'border-box',
            },
            [rootPrefixSelector]: {
                boxSizing: 'border-box',
                '&::before, &::after': {
                    boxSizing: 'border-box',
                },
            },
        },
    };
};
export const genFocusOutline = (token) => ({
    outline: `${token.lineWidthBold}px solid ${token.colorPrimaryBorder}`,
    outlineOffset: 1,
    transition: 'outline-offset 0s, outline 0s',
});
export const genFocusStyle = (token) => ({
    '&:focus-visible': Object.assign({}, genFocusOutline(token)),
});
